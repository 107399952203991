<template>
  <div style="max-width: 1980px;" class="row full-width">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl">
        <div class="text-h4 col-md-9">Assign Training</div>
      </div>
      <div class="row justify-between items-start">
        <div class="col-xs-12 col-sm-4 row justify-between">
          <q-radio v-model="requireableType" val="User Group" label="User Group" />
          <q-radio v-model="requireableType" val="Location" label="Location" />
          <q-radio v-model="requireableType" val="User" label="User" />
        </div>
        <div class="col-xs-12 col-sm-6">
          <q-btn v-if="requireableType === 'User'" class="full-width" color="primary" @click="showUserSearchModal = true">Find Users</q-btn>
          <filter-select-multiple v-else :options="requireableTypeList" v-bind:selection.sync="requireableTypeValue" emit-value map-options :label="`Select ${requireableType}`" />
        </div>
      </div>

      <q-dialog v-model="showUserSearchModal" persistent>
        <UserSearch scoped allowSelfSelect multiple @userSelected="requireableTypeValue = $event; showUserSearchModal = false;" @closeForm="showUserSearchModal = false" />
      </q-dialog>

      <div class="row full-width q-mt-xl" v-if="requireableTypeValue.length > 0">
        <div class="row full-width justify-center q-mb-xl">
          <q-btn class="col-6" @click="selectModal = true" color="primary">Add New Required Training</q-btn>
          <div v-if="requireableType === 'User'" class="col-12 text-center q-mt-lg">Selected Users: <span class="text-bold">{{ extractUserNames(requireableTypeValue) }}</span></div>
        </div>
        <div v-if="requireableType !== 'User' && requireableTypeValue.length === 1" class="row q-pa-md full-width">
          <q-list v-if="recurringModules.length > 0" separator class="full-width">
            <div class="text-h6">Modules</div>
            <module-list-item
              v-for="module in recurringModules"
              :module="module"
              :key="module.uid"
              :linkable="false"
              >
                <template v-slot:topRightAction>
                  <q-btn flat label="Remove Recurring Training" @click="removeTraining(module)" />
                </template>
            </module-list-item>
          </q-list>
          <div v-if="recurringCategories.length > 0" class="row full-width text-h6 q-mt-md q-mb-lg">Categories</div>
          <div v-if="recurringCategories.length > 0" class="row q-gutter-lg">
            <q-card v-for="category in recurringCategories" class="bg-lmsBackground" style="min-width: 250px" :key="category.assignable.uid">
              <q-img :src="category.assignable.thumbnail_path">
                <div v-if="category.assignable.settings.overlayTitle" class="absolute-full category-skim text-center text-weight-medium flex flex-center">
                  <div class="font-oswald" :style="`font-size: ${getFontSize(category.assignable.name)}`">{{ category.assignable.name }}</div>
                </div>
              </q-img>

              <q-card-actions class="row jutify-center justify-around">
                <q-btn flat @click="removeTraining(category)">Remove Recurring Training</q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div v-if="recurringModules.length === 0 && recurringCategories.length === 0" class="row full-width justify-center q-my-xl">
            <div class="text-subtitle1">Currently no recurring training for this {{ requireableType.toLowerCase() }}.</div>
          </div>
        </div>
        <div v-if="requireableType === 'User' && requireableTypeValue.length === 1"  class="row full-width">
          <div class="row full-width">
            <q-tabs v-model="whichTab" align="justify" class="col-12">
              <q-tab name="assigned" label="Currently Assigned Training" />
              <q-tab name="recurring" label="Recurring Training (User Specific)" />
            </q-tabs>
            <div class="col-12">
              <q-separator />
            </div>
          </div>
          <q-tab-panels v-model="whichTab" class="row full-width bg-lmsBackground">
            <q-tab-panel name="assigned">
              <q-list v-if="assignedModules.length > 0" separator class="full-width">
                <module-list-item
                  v-for="module in assignedModules"
                  :module="module"
                  :key="module.uid"
                  :linkable="false"
                  >
                    <template v-slot:topRightAction>
                      <q-btn flat label="Remove Assignment" @click="removeAssignment(module)" />
                    </template>
                </module-list-item>
              </q-list>
              <div v-else class="row full-width justify-center q-my-xl">
                <div class="text-subtitle1">Currently no assigned training for this user.</div>
              </div>
            </q-tab-panel>
            <q-tab-panel name="recurring">
              <q-list v-if="recurringModules.length > 0" separator class="full-width">
                <div class="text-h6">Modules</div>
                <module-list-item
                  v-for="module in recurringModules"
                  :module="module"
                  :key="module.uid"
                  :linkable="false"
                  >
                    <template v-slot:topRightAction>
                      <q-btn flat label="Remove Recurring Training" @click="removeTraining(module)" />
                    </template>
                </module-list-item>
              </q-list>
              <div v-if="recurringCategories.length > 0" class="row full-width text-h6 q-mt-md q-mb-lg">Categories</div>
              <div v-if="recurringCategories.length > 0" class="row q-gutter-lg">
                <q-card v-for="category in recurringCategories" class="bg-lmsBackground" style="min-width: 250px" :key="category.assignable.uid">
                  <q-img :src="category.assignable.thumbnail_path">
                    <div v-if="category.assignable.settings.overlayTitle" class="absolute-full category-skim text-center text-weight-medium flex flex-center">
                      <div class="font-oswald" :style="`font-size: ${getFontSize(category.assignable.name)}`">{{ category.assignable.name }}</div>
                    </div>
                  </q-img>

                  <q-card-actions class="row jutify-center justify-around">
                    <q-btn flat @click="removeTraining(category)">Remove Recurring Training</q-btn>
                  </q-card-actions>
                </q-card>
              </div>
              <div v-if="recurringModules.length === 0 && recurringCategories.length === 0" class="row full-width justify-center q-my-xl">
                <div class="text-subtitle1">Currently no recurring training assigned to this user.</div>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
        <div v-if="requireableTypeValue.length > 1" class="row full-width justify-center q-my-xl">
          <div class="text-subtitle1">You can assign to multiple {{ requireableType.toLowerCase() }}s, however, preview is not available when multiple are selected.</div>
        </div>
      </div>
    </div>

    <q-dialog v-model="selectModal" full-height @hide="isCategorySelected = false">
      <q-card style="min-width: 800px" class="bg-lmsBackground primaryText-text">
        <q-card-section>
          <div class="text-h4">Assign a Module</div>
        </q-card-section>
        <q-card-section class="row q-mt-lg">
          <div class="col-12 row justify-between q-mt-sm">
            <div v-if="category">
              Category Selected:
              <q-chip removable v-model="isCategorySelected" :label="category.name" />
            </div>
            <q-btn v-else color="primary" label="Find By Category" @click="showCategoryBrowseModal = true" />
            <q-input v-model="modalSearchText" @input="searchForModule" label="Search for specific module" dense style="width: 300px">
              <template v-slot:prepend>
                <q-icon name="search" class="primaryText-text" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="row justify-center q-pl-md">
            <div class="col-md-12">
              <div class="row q-gutter-lg q-pa-md full-width">
                <q-btn v-if="category" flat primary class="full-width" label="Assign Entire Category" @click="chooseAssignment(category, 'Category')" />
                <q-list separator class="full-width">
                  <module-list-item
                    v-for="module in modules"
                    :module="module"
                    :key="module.uid"
                    :linkable="false"
                    @moduleItemClick="chooseAssignment(module, 'Module')"
                    >
                      <template v-slot:topRightAction>
                        <q-btn flat label="Select" @click="chooseAssignment(module, 'Module')"/>
                      </template>
                  </module-list-item>
                </q-list>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="infoModal">
      <q-card style="min-width: 800px" class="q-pa-sm bg-lmsBackground primaryText-text">
        <q-card-section>
          <div class="text-subtitle1">Selected {{ assignmentObj.type}}:</div>
          <q-item>
            <q-item-section thumbnail>
              <q-img :src="assignmentObj.thumbnail_path" style="width: 250px" class="q-ml-md" />
            </q-item-section>
            <q-item-section top v-if="assignmentObj.type === 'Module'">
              <q-item-label class="text-h5">{{ assignmentObj.title }}</q-item-label>
              <q-item-label caption>{{ assignmentObj.description }}</q-item-label>
              <q-item-label lines="1" class="q-pa-xs q-pt-md">
                <q-chip v-for="(tag, index) in assignmentObj.tags" v-bind:key="index" dense square>{{tag}}</q-chip>
              </q-item-label>
            </q-item-section>
            <q-item-section top v-else>
              <q-item-label class="text-h5">{{ assignmentObj.name }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-card-section>
        <q-card-section>
          <div class="text-h6">Required Training Details</div>
          <div class="row q-mt-xl">
            <q-checkbox v-model="newRequirement.isRecurring" label="Recurring Training" class="col-12"/>
            <div class="row q-px-xl" v-if="newRequirement.isRecurring">
              <div class="text-caption text-italic">Selecting a date in the past will assign the training starting today but start recurring from the date and frequency you select.</div>
            </div>
            <div class="col-12 row q-gutter-xl q-px-xl" v-if="newRequirement.isRecurring">
              <q-select :options="newRequirement.recurringOptions" map-options v-model="newRequirement.recurrence" label="Frequency" class="col-3" popup-content-class="bg-lmsBackground primaryText-text" />
              <q-input v-model="recurrenceStartDate" @click="() => $refs.recStartDate.show()" label="Starting On" class="col-3">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="recStartDate" transition-show="scale" transition-hide="scale">
                      <q-date v-model="recurrenceStartDate" @input="() => $refs.recStartDate.hide()" mask="MM/DD/YYYY" class="bg-lmsBackground primaryText-text" />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>
          <div class="row q-mt-xl">
            <q-checkbox v-model="newRequirement.hasDueDate" label="Due Date" class="col-12"/>
            <div v-if="newRequirement.hasDueDate">
              <div v-if="newRequirement.isRecurring">
                <div class="row q-px-xl" v-if="newRequirement.hasDueDate">
                  <div class="text-caption text-italic">The due date will be set from the day the required training is assigned.</div>
                </div>
                <div class="col-12 row q-gutter-xl q-px-xl" v-if="newRequirement.hasDueDate">
                  <q-input v-model.number="dueDateNumber" type="number" class="col-3"/>
                  <q-select v-model="dueDateType" :options="dueDateTypes" class="col-3" popup-content-class="bg-lmsBackground primaryText-text" />
                </div>
              </div>
              <div v-else>
                <q-input v-model="customDueDate" @click="() => $refs.recCustomDueDate.show()" label="Due On" class="col-3">
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="recCustomDueDate" transition-show="scale" transition-hide="scale">
                        <q-date v-model="customDueDate" @input="() => $refs.recCustomDueDate.hide()" mask="MM/DD/YYYY" class="bg-lmsBackground primaryText-text" :options="optionsFn" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-mt-xl">
          <div class="text-h6">Summary</div>
          <div class="text-body">{{ summaryStatement }}</div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn @click="newRequirement.isRecurring || requireableType !== 'User' ? addRecurringTraining() : addAssignment()" color="primary">Assign Required Training</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showCategoryBrowseModal" full-width full-height>
      <CategoryBrowse @selectedCategory="selectedCategoryUid = $event.uid; showCategoryBrowseModal = false;" @closeModal="showCategoryBrowseModal = false" />
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar'
import ModuleListItem from 'components/ModuleListItem'
import FilterSelectMultiple from 'components/filterSelectMultiple'
import CategoryBrowse from 'components/CategoryBrowse'
import UserSearch from 'components/UserSearch'

export default {
  name: 'AssignTrainingPage',
  components: { ModuleListItem, FilterSelectMultiple, CategoryBrowse, UserSearch },
  data () {
    return {
      showCategoryBrowseModal: false,
      showUserSearchModal: false,
      whichTab: 'assigned',
      assignedModules: [],
      recurringModules: [],
      recurringCategories: [],
      requireableType: 'User Group',
      requireableTypeValue: [],
      assignmentStatement: 'The training will be assigned today.',
      category: null,
      categories: [],
      customRecStartDate: null,
      customDueDate: date.formatDate(new Date(), 'MM/DD/YYYY'),
      dueDateNumber: 1,
      dueDateType: 'month',
      dueDateTypes: ['day', 'week', 'month', 'year'],
      modalSearchText: null,
      modules: [],
      newRequirement: {
        hasDueDate: false,
        isRecurring: false,
        recurrence: { label: 'Yearly', value: { year: 1 } },
        recurringOptions: [
          { label: 'Triennial', value: { year: 3 } },
          { label: 'Biennial', value: { year: 2 } },
          { label: 'Yearly', value: { year: 1 } },
          { label: 'Quarterly', value: { month: 3 } },
          { label: 'Monthly', value: { month: 1 } },
          { label: 'Bi_Weekly', value: { days: 14 } },
          { label: 'Weekly', value: { days: 7 } },
          { label: 'Daily', value: { days: 1 } }
        ]
      },
      infoModal: false,
      selectModal: false,
      normalizer (node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.children
        }
      },
      selectedCategoryUid: null,
      selectedModule: {},
      assignmentObj: {},
      users: [],
      usersList: []
    }
  },
  computed: {
    requireableTypeList () {
      let requireableTypeList = []
      switch (this.requireableType) {
        case 'User Group':
          requireableTypeList = this.$store.getters['departments/list']
          break
        case 'Location':
          if (this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
            requireableTypeList = this.$store.getters['locations/listAll']
          } else {
            requireableTypeList = this.$store.getters['locations/listAll'].filter(location => location.value === this.$store.getters['auth/user'].customer.uid)
          }
          break
        case 'User':
          requireableTypeList = this.usersList
          break
        default:
          break
      }
      return Array.from(new Set(requireableTypeList.map(type => type.value))).map(uniqueTypeValue => {
        return {
          label: requireableTypeList.find(type => type.value === uniqueTypeValue).label,
          value: uniqueTypeValue
        }
      })
    },
    recurrenceStartDate: {
      get () {
        return date.formatDate(this.customRecStartDate || Date.now(), 'MM/DD/YYYY')
      },
      set (newValue) {
        this.customRecStartDate = newValue
      }
    },
    nextOccurenceDate () {
      let startDate = new Date(this.recurrenceStartDate)

      return date.addToDate(startDate, this.newRequirement.recurrence.value)
    },
    recurrenceStatement () {
      if (this.newRequirement.isRecurring) {
        return `It will be assigned on a ${this.newRequirement.recurrence.label} basis. The next assignment will be scheduled for ${date.formatDate(this.nextOccurenceDate, 'MMMM Do, YYYY')}.`
      }
      return `There will be no recurrence.`
    },
    dueDateStatement () {
      if (this.newRequirement.hasDueDate && this.newRequirement.isRecurring) {
        return `The training will be due ${this.dueDateNumber} ${this.dueDateType} from the date it is assigned.`
      } else if (this.newRequirement.hasDueDate) {
        return `The training will be due on ${this.customDueDate}`
      } else {
        return `There will be no due date.`
      }
    },
    summaryStatement () {
      return `${this.assignmentStatement} ${this.recurrenceStatement} ${this.dueDateStatement}`
    },
    customDueDateDays () {
      return this.customDueDate ? date.getDateDiff(this.customDueDate, new Date(), 'days') : null
    },
    isCategorySelected: {
      get () {
        return this.category !== null
      },
      set (newVal) {
        if (!newVal) {
          this.category = null
          this.modules = []
        }
      }
    }
  },
  watch: {
    requireableTypeValue (newVal) {
      if (this.requireableTypeValue.length === 1) {
        if (this.requireableType === 'User') {
          this.getUserAssignments(newVal[0].value)
          this.getRecurringTraining(newVal[0].value)
        } else {
          this.getRecurringTraining(newVal[0])
        }
      } else {
        this.assignedModules = []
        this.recurringModules = []
      }
    },
    selectModal () {
      if (this.selectModal) {
        this.resetModal()
      }
    },
    requireableType () {
      this.assignedModules = []
      this.recurringModules = []
      this.recurringCategories = []
      this.requireableTypeSingle = null
      this.requireableTypeValue = []
    },
    selectedCategoryUid (newUid, oldUid) {
      if (newUid) {
        this.modalSearchText = null
        this.category = (newUid !== undefined && newUid !== null) ? this.$store.getters['categories/getByUid'](this.categories, newUid) : null
        this.getModules()
      }
    },
    dueDateNumber () {
      if (this.dueDateNumber <= 1) {
        this.dueDateNumber = 1
        this.dueDateTypes = ['day', 'week', 'month', 'year']
        if (this.dueDateType.slice(-1) === 's') {
          this.dueDateType = this.dueDateType.substring(0, this.dueDateType.length - 1)
        }
      } else {
        this.dueDateTypes = ['days', 'weeks', 'months', 'years']
        if (this.dueDateType.slice(-1) !== 's') {
          this.dueDateType = `${this.dueDateType}s`
        }
      }
    },
    recurrenceStartDate () {
      let inFuture = new Date(this.recurrenceStartDate) > Date.now()
      let startDate = new Date(this.recurrenceStartDate)
      if (inFuture) {
        this.assignmentStatement = `The training will be scheduled to be assigned on ${date.formatDate(startDate, 'MMMM Do, YYYY')}.`
      } else {
        this.assignmentStatement = 'The training will be assigned today.'
      }
    }
  },
  methods: {
    resetModal () {
      this.selectedCategoryUid = null
      this.modalSearchText = null
      this.selectedModule = {}
      this.assignmentObj = {}
      this.infoModal = false
      this.dueDateNumber = 1
      this.dueDateType = 'month'
      this.newRequirement = {
        hasDueDate: false,
        isRecurring: false,
        recurrence: { label: 'Yearly', value: { year: 1 } },
        recurringOptions: [
          { label: 'Triennial', value: { year: 3 } },
          { label: 'Biennial', value: { year: 2 } },
          { label: 'Yearly', value: { year: 1 } },
          { label: 'Quarterly', value: { month: 3 } },
          { label: 'Monthly', value: { month: 1 } },
          { label: 'Bi_Weekly', value: { days: 14 } },
          { label: 'Weekly', value: { days: 7 } },
          { label: 'Daily', value: { days: 1 } }
        ]
      }
    },
    selectModule (module) {
      this.selectedModule = module
      this.selectModal = false
      this.infoModal = true
    },
    chooseAssignment (obj, type) {
      this.assignmentObj = obj
      this.assignmentObj.type = type
      this.selectModal = false
      this.infoModal = true
    },
    async getUserAssignments (userUid) {
      this.$q.loading.show()
      let res = await this.$axios.get(`${this.$consts.GET_ASSIGNS}?user_uid=${userUid}`)

      if (res.request.length < 1) {
        this.assignedModules = []
        this.$q.loading.hide()
        return
      }

      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: '',
          params: {
            facetFilters: [
              res.request.map(module => `uid:${module.assignable_uid}`)
            ]
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.assignedModules = content.results['0'].hits
        this.assignedModules.forEach(module => {
          // we can put all the assignment data as an object in the module object. That way we can add more valuable info like 'Next Assignment Date', 'Due Date', etc.
          module.assignedUid = res.request.find(training => training.assignable_uid === module.uid).uid
          module.recurringUid = res.request.find(training => training.assignable_uid === module.uid).recurring_uid
        })
      })
      this.$q.loading.hide()
    },
    async getRecurringTraining (requirableUid) {
      this.$q.loading.show()
      let res = await this.$axios.get(`${this.$consts.GET_RECURRING_TRAINING}?requirable_uid=${requirableUid}`)

      if (res.request.length < 1) {
        this.recurringModules = []
        this.$q.loading.hide()
        return
      }

      res.request.forEach(training => {
        if (training.assignable_type === `Module`) {
          this.recurringModules.push(training)
        } else {
          this.recurringCategories.push(training)
        }
      })

      if (this.recurringModules.length > 0) {
        this.$moduleSearch.clearCache()
        this.$moduleSearch.search([
          {
            indexName: this.$consts.MODULES_INDEX,
            query: '',
            params: {
              facetFilters: [
                this.recurringModules.map(module => `uid:${module.assignable_uid}`)
              ]
            }
          }
        ], (err, content) => {
          if (err) throw err
          this.recurringModules = content.results['0'].hits
          this.recurringModules.forEach(module => {
            module.recurringUid = res.request.find(training => training.assignable_uid === module.uid).uid
          })
        })
      }
      this.$q.loading.hide()
    },
    searchForModule () {
      if (this.selectedCategoryUid) {
        this.selectedCategoryUid = null
      }
      let filter = this.assignedModules.map(module => `uid:-${module.uid}`)
      filter.push('customer_uid:' + this.$store.getters['auth/user'].company.uid)
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: this.modalSearchText,
          params: {
            facetFilters: filter
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.modules = content.results['0'].hits
      })
    },
    getModules () {
      let filter = this.assignedModules.map(module => `uid:-${module.uid}`)
      filter.push('categories:' + this.category.uid)
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: '',
          params: {
            hitsPerPage: 50,
            facetFilters: filter
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.modules = content.results['0'].hits
      })
    },
    async addAssignment () {
      this.$q.loading.show()
      try {
        await this.$axios.post(this.$consts.ADD_ASSIGNMENT_URL, {
          user_uids: this.requireableTypeValue.map(user => user.value), // array of users
          due_date_duration: this.getDueDate(),
          assignable_uid: this.assignmentObj.uid,
          assignable_type: this.assignmentObj.type
        }).then((response) => {
          this.$moduleSearch.clearCache()
          this.$moduleSearch.search([
            {
              indexName: this.$consts.MODULES_INDEX,
              query: '',
              params: {
                facetFilters: [
                  response.request.map(module => `uid:${module.assignable_uid}`)
                ]
              }
            }
          ], (err, content) => {
            if (err) throw err
            content.results['0'].hits.forEach(hit => {
              // for each hit, we need to attach the assignment uid to the matching assignable_uid (module uid) from the response
              let matchingAssignment = response.request.find(assignment => hit.uid === assignment.assignable_uid)

              if (matchingAssignment) {
                hit.assignedUid = matchingAssignment.uid
              }

              this.assignedModules.push(hit)
            })
          })
        })
        this.$successMsg('Module Assigned to User')
        this.infoModal = false
      } catch (e) {
        this.$failureMsg()
      }
      this.$q.loading.hide()
    },
    async addRecurringTraining () {
      this.$q.loading.show()
      try {
        await this.$axios.post(this.$consts.ADD_RECURRING_TRAINING, {
          requirable_uid: this.requireableType.toLowerCase() === 'user' ? this.requireableTypeValue.map(user => user.value) : this.requireableTypeValue,
          requirable_type: this.requireableType.toLowerCase() === 'user group' ? 'department' : this.requireableType.toLowerCase(),
          assignable_uid: this.assignmentObj.uid,
          assignable_type: this.assignmentObj.type,
          recurring_duration: this.newRequirement.isRecurring ? this.newRequirement.recurrence.label.toLowerCase() : null,
          recurring_date: this.newRequirement.isRecurring ? this.recurrenceStartDate : null,
          due_date_duration: this.getDueDate()
        }).then((response) => {
          if (response.assignable_type === 'Module') {
            this.$moduleSearch.clearCache()
            this.$moduleSearch.search([
              {
                indexName: this.$consts.MODULES_INDEX,
                query: '',
                params: {
                  facetFilters: [
                    `uid:${response.assignable_uid}`
                  ]
                }
              }
            ], (err, content) => {
              if (err) throw err
              content.results['0'].hits['0'].recurringUid = response.uid
              this.recurringModules.push(content.results['0'].hits['0'])
              if (this.requireableType === 'User' && this.requireableTypeValue.length === 1) {
                content.results['0'].hits['0'].assignedUid = response.uid
                this.assignedModules.push(content.results['0'].hits['0'])
              }
            })
          } else {
            this.recurringCategories.push(response)
          }
        })
        this.$successMsg('The training has been scheduled')
        this.infoModal = false
      } catch (e) {
        this.$failureMsg()
      }
      this.$q.loading.hide()
    },
    getDueDate () {
      if (this.newRequirement.isRecurring && this.newRequirement.hasDueDate) {
        return `${this.dueDateNumber} ${this.dueDateType}`
      } else if (this.newRequirement.hasDueDate && this.customDueDate) {
        return `${this.customDueDateDays} days`
      } else {
        return null
      }
    },
    optionsFn (pDate) {
      return pDate >= date.formatDate(new Date(), 'YYYY/MM/DD')
    },
    async removeTraining (training) {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this recurring training? All of its current assignments will also be deleted.',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        this.$q.loading.show()
        try {
          await this.$axios.delete(`${this.$consts.REMOVE_RECURRING_TRAINING}/${training.recurringUid || training.uid}`)
          this.recurringModules = this.recurringModules.filter(requiredTraining => requiredTraining.recurringUid !== training.recurringUid)
          this.assignedModules = this.assignedModules.filter(assignedTraining => assignedTraining.recurringUid !== training.recurringUid)
          this.recurringCategories = this.recurringCategories.filter(recurringCategories => recurringCategories.recurringUid !== training.recurringUid)
          this.$successMsg('The recurring training has been deleted')
        } catch (e) {
          this.$failureMsg()
        }
        this.$q.loading.hide()
      })
    },
    async removeAssignment (module) {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this assignment?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        this.$q.loading.show()
        try {
          await this.$axios.delete(`${this.$consts.REMOVE_ASSIGNMENT_URL}/${module.assignedUid}`)
          this.assignedModules = this.assignedModules.filter(requiredTraining => requiredTraining.assignedUid !== module.assignedUid)
          this.$successMsg('The assignment has been deleted')
        } catch (e) {
          this.$failureMsg()
        }
        this.$q.loading.hide()
      })
    },
    extractUserNames (users) {
      return users.map(user => user.label).join(', ')
    },
    getFontSize (name) {
      if (name.length <= 6) {
        // console.log(`${name} - ${name.length} - 52px`)
        return '56px'
      } else if (name.length <= 10) {
        return '48px'
      } else if (name.length <= 14) {
        return '40px'
      } else if (name.length <= 18) {
        return '32px'
      } else if (name.length <= 22) {
        return '28px'
      } else if (name.length <= 26) {
        return '24px'
      } else if (name.length <= 30) {
        return '20px'
      } else {
        return '16px'
      }
    }
  },
  async created () {
    this.$store.dispatch('locations/fetch')
    this.$store.dispatch('departments/fetch')

    this.$store.dispatch('categories/fetch', 'view').then((resp) => {
      this.categories = resp
    })
    let res
    if (this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
      res = await this.$store.dispatch('users/fetchFullList')
    } else {
      res = await this.$store.dispatch('users/fetchList')
    }
    this.usersList = res.map(user => {
      return {
        value: user.uid,
        label: user.name
      }
    })
  }
}
</script>
